export function isSafeRelativePath(path: string): boolean {
  if (!path) return false;
  
  if (/^\w+:\//.test(path)) return false;
  
  if (path.startsWith("//")) return false;
  
  if (!path.startsWith("/")) return false;

  return true;
}