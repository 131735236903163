import { z as zod } from "zod";

import { trimPhone, validatePhone } from "@/utils/phone";

export const requiredStringValidation = zod
  .string({
    required_error: "value_required",
  })
  .trim()
  .min(1, "value_required");

export const nameRu = requiredStringValidation.regex(
  /^[\sЁА-яё-]+$/,
  "name_invalid"
);
export const nameEn = requiredStringValidation.regex(
  /^[\s'.A-Z`a-z-]+$/,
  "english_name_invalid"
);

export const emailValidation = requiredStringValidation.refine(
  (email) => email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
  "invalid_email"
);

export const telegramValidation = requiredStringValidation
  .regex(/^(@|(https:\/\/)?t\.me\/)?\w{5,32}$/, "invalid_telegram")
  .transform((value) => {
    return value.replace(/^(@|(https:\/\/)?t\.me\/)/, "");
  });

export const companyValidation = requiredStringValidation;

export const positionValidation = requiredStringValidation;

export const passwordValidation = zod
  .string({
    required_error: "password_required",
  })
  .min(1, "password_required");

export const strictPasswordValidation = passwordValidation.min(
  6,
  "password_min_6"
);

export const phoneValidation = requiredStringValidation.transform(
  (value, ctx) => {
    const numbers = trimPhone(value);

    if (!numbers) {
      if (value.trim()) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: "invalid_phone",
          fatal: true,
        });
      } else {
        ctx.addIssue({
          code: zod.ZodIssueCode.too_small,
          minimum: 1,
          inclusive: true,
          type: "string",
          message: "value_required",
          fatal: true,
        });
      }

      return zod.NEVER;
    }

    const isValid = validatePhone(numbers);

    if (!isValid) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message: "invalid_phone",
        fatal: true,
      });

      return zod.NEVER;
    }

    return `${numbers}`;
  }
);

export const smsCodeValidation = zod
  .string({
    required_error: "code_required",
  })
  .trim()
  .min(1, "code_required")
  .regex(/\d{4}$/, "code_4");

export const passwordWithConfirmation = zod
  .object({
    password: strictPasswordValidation,
    password_confirmation: zod
      .string({
        required_error: "value_required",
      })
      .min(1, "value_required"),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "password_confirmation_same",
    path: ["password_confirmation"],
  });

// TODO: use `switch` when it'll be available
// https://github.com/colinhacks/zod/issues/2106
export const phoneOrEmailValidation = requiredStringValidation.transform(
  (value, ctx) => {
    const isPhone = value.startsWith("+");

    const validator = isPhone ? phoneValidation : emailValidation;
    const res = validator.safeParse(value);

    if (!res.success) {
      if (isPhone) {
        res.error.issues.forEach(ctx.addIssue);
      } else {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: "login_invalid",
          fatal: true,
        });
      }

      return zod.NEVER;
    }

    return res.data;
  }
);
