import ErrorResolver from "@/components/errors/resolver/ErrorsResolver";
import Paths from "@/constants/paths";
import accountMenuLoader from "@/features/account-menu/loader";
import authGuardLoader from "@/features/auth/layout/loader";
import CourseMenuLayout from "@/features/course-menu/CourseMenuLayout";
import courseMenuLoader from "@/features/course-menu/courseMenuLoader";
import HelpLayout from "@/features/help/layout/HelpLayout";
import helpLoader from "@/features/help/layout/loader";
import createLazyLoader from "@/utils/router/createLazyLoader";
import createLazyRoute from "@/utils/router/createLazyRoute";
import defineRoutesConfig from "@/utils/router/defineRoutesConfig";
import { impersonateLoader } from "./pages/impersonate/loader";

import AccountMenuLayout from "./features/account-menu/AccountMenuLayout";
import AuthGuardLayout from "./features/auth/layout/AuthGuardLayout";
import AppLayout from "./layouts/app/AppLayout";
import appLayoutLoader from "./layouts/app/loader";
import BaseLayout from "./layouts/base/BaseLayout";
import DefaultMenuLayout from "./layouts/menu/default/DefaultMenuLayout";
import modalsLayoutLoader from "./layouts/modals/loader";
import ModalsLayout from "./layouts/modals/ModalsLayout";

// TODO: generate possible paths from routes
const routes = defineRoutesConfig({
  Component: AppLayout,
  loader: appLayoutLoader,
  children: defineRoutesConfig([
    {
      Component: ModalsLayout,
      ErrorBoundary: ErrorResolver,
      loader: modalsLayoutLoader,
      children: defineRoutesConfig([
        {
          Component: AuthGuardLayout,
          loader: authGuardLoader("un-auth"),
          children: defineRoutesConfig([
            {
              path: Paths.REGISTRATION_FORM,
              loader: createLazyLoader(
                import("@/pages/registration/loader")
              ),
              lazy: createLazyRoute(
                () => import("@/pages/registration/page")
              ),
            },
          ]),
        },
        {
          Component: BaseLayout,
          children: defineRoutesConfig([
            {
              path: Paths.VERIFY_EMAIL,
              lazy: createLazyRoute(() => import("@/pages/email/verify/page")),
            },
            {
              path: Paths.IMPERSONATE, // "/impersonate"
              loader: impersonateLoader,
              lazy: createLazyRoute(() => import("@/pages/impersonate/page")),
            },
            {
              path: Paths.RESTORE_PASSWORD,
              loader: createLazyLoader(
                import("@/pages/restore-password/loader")
              ),
              lazy: createLazyRoute(
                () => import("@/pages/restore-password/page")
              ),
            },
            {
              path: Paths.CHANGE_PASSWORD,
              loader: createLazyLoader(
                import("@/pages/change-password/loader")
              ),
              lazy: createLazyRoute(
                () => import("@/pages/change-password/page")
              ),
            },
            {
              Component: AuthGuardLayout,
              loader: authGuardLoader("un-auth"),
              children: defineRoutesConfig([
                {
                  Component: DefaultMenuLayout,
                  children: defineRoutesConfig([
                    {
                      path: Paths.LOGIN,
                      loader: createLazyLoader(import("@/pages/login/loader")),
                      lazy: createLazyRoute(() => import("@/pages/login/page")),
                    },
                    {
                      path: Paths.REGISTRATION,
                      loader: createLazyLoader(
                        import("@/pages/registration/loader")
                      ),
                      lazy: createLazyRoute(
                        () => import("@/pages/registration/page")
                      ),
                    },
                  ]),
                },
              ]),
            },
            {
              Component: AuthGuardLayout,
              loader: authGuardLoader("auth"),
              children: defineRoutesConfig([
                {
                  Component: HelpLayout,
                  loader: helpLoader,
                  children: defineRoutesConfig([
                    {
                      Component: DefaultMenuLayout,
                      children: defineRoutesConfig({
                        path: Paths.COURSES,
                        loader: createLazyLoader(
                          import("@/pages/courses/loader")
                        ),
                        lazy: createLazyRoute(
                          () => import("@/pages/courses/page")
                        ),
                      }),
                    },
                    {
                      path: Paths.ACCOUNT,
                      Component: AccountMenuLayout,
                      loader: accountMenuLoader,
                      children: defineRoutesConfig([
                        {
                          index: true,
                          loader: createLazyLoader(
                            import("@/pages/account/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/account/page")
                          ),
                        },
                        {
                          path: Paths.ACCOUNT_PAYMENTS,
                          loader: createLazyLoader(
                            import("@/pages/account/payments/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/account/payments/page")
                          ),
                        },
                        {
                          path: Paths.ACCOUNT_CERTIFICATES,
                          loader: createLazyLoader(
                            import("@/pages/account/certificates/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/account/certificates/page")
                          ),
                        },
                      ]),
                    },
                    {
                      path: Paths.VIDEO_SECRET,
                      loader: createLazyLoader(
                        import("@/pages/video/secret/loader")
                      ),
                      lazy: createLazyRoute(
                        () => import("@/pages/video/secret/page")
                      ),
                    },
                    {
                      path: Paths.VIDEO,
                      loader: createLazyLoader(import("@/pages/video/loader")),
                      lazy: createLazyRoute(() => import("@/pages/video/page")),
                    },
                  ]),
                },

                {
                  path: Paths.COURSE,
                  Component: CourseMenuLayout,
                  loader: courseMenuLoader,
                  children: defineRoutesConfig([
                    {
                      Component: HelpLayout,
                      loader: helpLoader,
                      children: defineRoutesConfig([
                        {
                          index: true,
                          loader: createLazyLoader(
                            import("@/pages/course/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_ABOUT,
                          loader: createLazyLoader(
                            import("@/pages/course/about/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/about/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_PARAMETERS,
                          loader: createLazyLoader(
                            import("@/pages/course/parameters/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/parameters/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_TASK,
                          loader: createLazyLoader(
                            import("@/pages/course/task/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/task/page")
                          ),
                          shouldRevalidate: ({ currentParams, nextParams }) => {
                            return currentParams["task"] !== nextParams["task"];
                          },
                        },
                        {
                          path: Paths.COURSE_TEST,
                          loader: createLazyLoader(
                            import("@/pages/course/test/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/test/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_SELECT_COHORT,
                          loader: createLazyLoader(
                            import("@/pages/course/select-cohort/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/select-cohort/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_PAY,
                          loader: createLazyLoader(
                            import("@/pages/course/pay/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/pay/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_VIDEOS,
                          loader: createLazyLoader(
                            import("@/pages/course/videos/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/videos/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_LIVE_CLASSES,
                          loader: createLazyLoader(
                            import("@/pages/course/live-classes/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/live-classes/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_LIVE_CLASS,
                          loader: createLazyLoader(
                            import("@/pages/course/live-class/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/live-class/page")
                          ),
                        },
                        {
                          path: Paths.COURSE_CONVERSATION,
                          loader: createLazyLoader(
                            import("@/pages/course/conversation/loader")
                          ),
                          lazy: createLazyRoute(
                            () => import("@/pages/course/conversation/page")
                          ),
                        },
                      ]),
                    },
                  ]),
                },
              ]),
            },
          ]),
        },

        {
          path: "*",
          loader: createLazyLoader(import("@/pages/errors/404/loader")),
          lazy: createLazyRoute(() => import("@/pages/errors/404/page")),
        },

        {
          path: "sentry-test-exception",
          children: [
            {
              path: "loader",
              loader: createLazyLoader(
                import("@/pages/sentry-test-exception/loader/loader")
              ),
              lazy: createLazyRoute(
                () => import("@/pages/sentry-test-exception/loader/page")
              ),
            },

            {
              path: "page",
              lazy: createLazyRoute(
                () => import("@/pages/sentry-test-exception/page/page")
              ),
            },

            {
              path: "action",
              lazy: createLazyRoute(
                () => import("@/pages/sentry-test-exception/action/page")
              ),
            },
          ],
        },
      ]),
    },
  ]),
});

export default routes;
