import { z as zod } from "zod";

import { courseFeatureSchema } from "../feature";
import { zodDate } from "../helpers";
import { labelsArraySchema } from "../label";

const courseCustomBlockSchema = zod.object({
  id: zod.number(),
  text: zod.string(),
});

const courseFeatureListSchema = zod.object({
  id: zod.number(),
  title: zod.string(),
  description: zod.string(),
  // TODO: remove this
  // color_text: zod.string().nullish(),
  footer: zod.string().nullable(),
  features: zod.array(courseFeatureSchema),
});

const courseInfrastructureTextSchema = zod.string();

const courseComponentSchema = zod.object({
  id: zod.number(),
  text: zod.string(),
  // TODO: remove this
  // sort: zod.number(),
});

const courseCategorySchema = zod.object({
  id: zod.number(),
  name: zod.string(),
});

export const courseCommonInfoSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  url: zod.string(),
  links: zod
    .object({
      about: zod.string().nullable(),
      video: zod.string().nullable(),
      preview: zod.string().nullable(),
      reviews: zod.string().nullable(),
    })
    .nullish(),
  course_category: courseCategorySchema.nullish(),
  labels: labelsArraySchema.nullish(),
  components: zod.array(courseComponentSchema).nullish(),
  custom_blocks: zod.array(courseCustomBlockSchema).nullish(),
  feature_list: courseFeatureListSchema.nullish(),
  infrastructure_text: courseInfrastructureTextSchema.nullish(),
});

const courseNewVideoSchema = zod.object({
  id: zod.number(),
  preview_image: zod.string().nullish(),
  duration: zod.string(),
  name: zod.string(),
  recorded_at: zodDate,
  speakers: zod.array(
    zod.object({
      id: zod.number().optional(),
      name: zod.string(),
    })
  ),
  watched: zod.boolean(),
});

export const paidCourseResponseSchema = zod.object({
  is_paid: zod.literal(true),
  last_task: zod
    .object({
      id: zod.number(),
      // module: zod.object({
      //   index: zod.number(),
      //   name: zod.string().optional(),
      // }),
      // task: zod.object({
      //   index: zod.number(),
      //   name: zod.string(),
      // }),
    })
    .nullish(),
  last_visit: zodDate.nullish(),
  new_videos: zod.array(courseNewVideoSchema),
  is_active: zod.boolean(),
});

export const unpaidCourseResponseSchema = courseCommonInfoSchema.extend({
  is_paid: zod.literal(false),
});

export const courseResponseDataSchema = zod.discriminatedUnion("is_paid", [
  paidCourseResponseSchema,
  unpaidCourseResponseSchema,
]);

export type Course = zod.infer<typeof courseResponseDataSchema>;

export type CourseCommonInfo = zod.infer<typeof courseCommonInfoSchema>;

export type PaidCourse = zod.infer<typeof paidCourseResponseSchema>;

export type UnpaidCourse = zod.infer<typeof unpaidCourseResponseSchema>;

export type CourseCategory = zod.infer<typeof courseCategorySchema>;

export type CourseCustomBlock = zod.infer<typeof courseCustomBlockSchema>;
export type CourseFeatureList = zod.infer<typeof courseFeatureListSchema>;
export type CourseComponent = zod.infer<typeof courseComponentSchema>;
export type CourseInfrastructureText = zod.infer<
  typeof courseInfrastructureTextSchema
>;

export type CourseNewVideo = zod.infer<typeof courseNewVideoSchema>;

export { type CourseFeature } from "../feature";
